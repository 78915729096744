import React from 'react'

import {
  ArrowRightIcon,
  CircleButton,
  Inline,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'
import { Code } from '../../components/Code'

const onButtonClick = () => alert('Click!')

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="CircleButton"
    components={[{ component: CircleButton, restElement: true }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Playground>
        <Inline gap="md">
          <CircleButton
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
          >
            Label
          </CircleButton>
          <CircleButton
            disabled
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
          >
            Label
          </CircleButton>
          <CircleButton
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            size="sm"
          >
            Label
          </CircleButton>
          <CircleButton
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            size="xs"
          >
            Label
          </CircleButton>
          <CircleButton
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            size="xxs"
          >
            Label
          </CircleButton>
        </Inline>
      </Playground>
      <Playground>
        <Inline gap="md">
          <CircleButton
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            variant={'plain'}
          >
            Label
          </CircleButton>
          <CircleButton
            disabled
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            variant={'plain'}
          >
            Label
          </CircleButton>
          <CircleButton
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            size="sm"
            variant={'plain'}
          >
            Label
          </CircleButton>
          <CircleButton
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            size="xs"
            variant={'plain'}
          >
            Label
          </CircleButton>
          <CircleButton
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            size="xxs"
            variant={'plain'}
          >
            Label
          </CircleButton>
        </Inline>
      </Playground>
      <Playground isDark>
        <Inline gap="md">
          <CircleButton
            color="white"
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            variant="outline"
          >
            Label
          </CircleButton>
          <CircleButton
            color="white"
            disabled
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            variant="outline"
          >
            Label
          </CircleButton>
          <CircleButton
            color="white"
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
          >
            Label
          </CircleButton>
          <CircleButton
            color="white"
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            size="sm"
            variant="outline"
          >
            Label
          </CircleButton>
          <CircleButton
            color="white"
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            size="xs"
            variant="outline"
          >
            Label
          </CircleButton>
          <CircleButton
            color="white"
            icon={<ArrowRightIcon />}
            onClick={onButtonClick}
            size="xxs"
            variant="outline"
          >
            Label
          </CircleButton>
        </Inline>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-md">
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              icon={<ArrowRightIcon />}
              shape="circle"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              disabled
              icon={<ArrowRightIcon />}
              shape="circle"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              icon={<ArrowRightIcon />}
              size="sm"
              shape="circle"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              icon={<ArrowRightIcon />}
              size="xs"
              shape="circle"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md">
            <ButtonHTMLExample
              icon={<ArrowRightIcon />}
              size="xxs"
              shape="circle"
            >
              Label
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-md">
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              icon={<ArrowRightIcon />}
              shape="circle"
              variant="plain"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              disabled
              icon={<ArrowRightIcon />}
              shape="circle"
              variant="plain"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              icon={<ArrowRightIcon />}
              shape="circle"
              size="sm"
              variant="plain"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              icon={<ArrowRightIcon />}
              size="xs"
              shape="circle"
              variant="plain"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md">
            <ButtonHTMLExample
              icon={<ArrowRightIcon />}
              size="xxs"
              shape="circle"
              variant="plain"
            >
              Label
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
      <Playground
        format="html"
        isDark
      >
        <div className="flex flex-wrap mt-n-md">
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              color="white"
              icon={<ArrowRightIcon />}
              shape="circle"
              variant="outline"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              color="white"
              disabled
              icon={<ArrowRightIcon />}
              shape="circle"
              variant="outline"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              color="white"
              icon={<ArrowRightIcon />}
              shape="circle"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              color="white"
              icon={<ArrowRightIcon />}
              shape="circle"
              size="sm"
              variant="outline"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              color="white"
              icon={<ArrowRightIcon />}
              size="xs"
              shape="circle"
              variant="outline"
            >
              Label
            </ButtonHTMLExample>
          </div>
          <div className="mt-md">
            <ButtonHTMLExample
              color="white"
              icon={<ArrowRightIcon />}
              size="xxs"
              shape="circle"
              variant="outline"
            >
              Label
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Painikkeelle tulee antaa teksti, joka on järkevä ruudunlukijalla
        luettuna (vaikka tekstiä ei näytetäkään ruudulla). Teksti annetaan{' '}
        <Code>Button</Code>in childreninä tai vaihtoehtoisesti{' '}
        <Code>ariaLabel</Code>-attribuutin avulla, jos childreniä ei jostakin
        syystä voida käyttää. Ikonipainikkeita tulee käyttää harkiten, koska ei
        voida pitää itsestäänselvänä, että käyttäjä ymmärtää, mitä ikoni
        tarkoittaa.
      </Paragraph>
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>Varmista, että painikkeella on kuvaava label.</ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
